import React from "react"
import { GetStartedButton } from "../../../../shared"
import {
  ButtonContainer,
  CaptionContainer,
  Description,
  FSaHsa,
  ImageSection,
  LearnMoreButton,
  OuterSectionWrapper,
  Price,
  Savings,
  SectionImage,
  SectionTitle,
  SectionWrapper,
  Stat,
  TextSection,
  RecentStudy,
  Popover,
  TriangleContainer,
} from "./styled"
import Info from "../../../../../images/Info.svg"
import { useWindowSize } from "../../../../../components/hooks"

interface ISectionProps {
  title: string
  price: string
  savings: string
  description: string
  getStartedLink: string
  learnMoreLink?: string
  image: string
  icon: string
  imageText: string
  zIndex?: number
  hideBoxShadow?: boolean
  stat?: boolean
  statLink?: string
  fsa?: boolean
}

export const Section = React.forwardRef(
  (
    {
      title,
      price,
      savings,
      description,
      learnMoreLink,
      image,
      icon,
      imageText,
      zIndex,
      hideBoxShadow,
      stat,
      statLink,
      fsa,
    }: ISectionProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { width } = useWindowSize()
    const isMobile = width < 769

    return (
      <OuterSectionWrapper
        ref={ref}
        hideBoxShadow={hideBoxShadow}
        zIndex={zIndex}
        isMobile={isMobile}
      >
        <SectionWrapper>
          <TextSection>
            <SectionTitle>{title}</SectionTitle>
            <div>
              <Price>{price}/month</Price>
              <Popover></Popover>
              {fsa && (
                <FSaHsa>
                  FSA / HSA eligible
                  <Popover tabIndex={0}>
                    <img src={Info} alt="" />
                    <div className="popover-content">
                      <TriangleContainer>
                        <div className="inner-triangle" />
                      </TriangleContainer>
                      <p>
                        Cerebral accepts FSA/HSA as a form of payment. You may
                        enter that card information as your payment method on
                        file. Most FSA/HSA plans cover some or all costs for
                        mental health care services. Please contact your plan
                        administrator for details.
                      </p>
                    </div>
                  </Popover>
                </FSaHsa>
              )}
              <Savings>{savings}</Savings>
            </div>
            <Description>{description}</Description>
            <ButtonContainer>
              <GetStartedButton />
              {learnMoreLink && (
                <LearnMoreButton to={learnMoreLink}>Learn more</LearnMoreButton>
              )}
            </ButtonContainer>
          </TextSection>
          <ImageSection>
            <SectionImage src={image} alt="" />
            <svg width="0" height="0">
              <clipPath id="svgClip" clipPathUnits="objectBoundingBox">
                <path d="M0 0.454545C0 0.203507 0.203507 0 0.454545 0C0.705584 0 0.909091 0.203507 0.909091 0.454545V1H0V0.454545Z"></path>
              </clipPath>
            </svg>
            <CaptionContainer>
              <img src={icon} alt={imageText} />
              <p
                dangerouslySetInnerHTML={{
                  __html: imageText,
                }}
              />
            </CaptionContainer>
            {stat && (
              <Stat>
                According to a{" "}
                <RecentStudy href={statLink} target="_blank">
                  recent study
                </RecentStudy>{" "}
                of people with mild to moderate symptoms
              </Stat>
            )}
          </ImageSection>
        </SectionWrapper>
      </OuterSectionWrapper>
    )
  }
)
