import { Link } from "gatsby"
import styled from "styled-components"
import { colors } from "../../../../themes/colors"

export const OuterSectionWrapper = styled.div<{
  zIndex?: number
  hideBoxShadow?: boolean
  isMobile?: boolean
}>`
  display: flex;
  justify-content: center;
  ${({ isMobile }) => (isMobile ? "" : "position: sticky;")}
  background-color: ${colors.c_white[100]};
  top: 236px;

  box-shadow: ${({ hideBoxShadow, isMobile }) =>
    hideBoxShadow || isMobile
      ? ""
      : `0px -4px 26px -4px rgba(33, 27, 27, 0.09), 
      -4px 0px 26px -4px rgba(33, 27, 27, 0.09), 
      4px 0px 26px -4px rgba(33, 27, 27, 0.09);`};

  z-index: ${({ zIndex }) => zIndex || 1};

  @media (max-width: 768px) {
    top: 200px;
    padding: 0 24px;
  }
`

export const SectionWrapper = styled.div`
  max-width: 1024px;
  padding: 64px 16px;
  margin: 0 auto;
  display: flex;
  gap: 145px;
  justify-content: center;

  @media (max-width: 1023px) {
    gap: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 24px 0px;
    margin: 0;
    gap: 24px;
    align-items: center;
  }
`

export const SectionTitle = styled.h3`
  color: ${colors.c_black[100]};
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 125%;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 472px;

  @media (max-width: 1023px) {
    width: 340px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Price = styled.p`
  color: ${colors.c_black[100]};
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
`

export const FSaHsa = styled.p`
  color: ${colors.c_grey[800]};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 2px;
  img {
    cursor: pointer;
  }
`

export const Savings = styled.p`
  padding: 8px 16px;
  font-family: Poppins;
  background-color: ${colors.c_yellow[200]};
  color: ${colors.c_black[100]};
  border-radius: 24px;
  width: fit-content;
`

export const Description = styled.p`
  color: ${colors.c_grey[900]};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

export const ButtonContainer = styled.div`
  max-width: 340px;
  a {
    height: 56px;
  }

  @media (max-width: 768px) {
    max-width: none;
  }
`

export const LearnMoreButton = styled(Link)`
  width: 100%;
  text-align: center;
  padding: 0 24px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.c_primary[600]};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
`

export const ImageSection = styled.div`
  position: relative;
  width: 400px;
  height: 440px;

  @media (max-width: 1023px) {
    width: 340px;
    height: 374px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`

export const SectionImage = styled.img`
  clip-path: inset(0% 0% 0% 0% round 400px 400px 0 0);
  width: 400px;
  height: 440px;
  object-fit: cover;

  @media (max-width: 1023px) {
    width: 340px;
    height: 374px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`

export const CaptionContainer = styled.div`
  position: absolute;
  bottom: 48px;
  left: -56px;
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: ${colors.c_white[100]};
  padding: 16px;
  border-radius: 16px;
  width: max-content;

  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
  max-width: 440px;

  @media (max-width: 1023px) {
    display: none;
  }
  p {
    color: ${colors.c_black[100]};
    font-family: Poppins;
    font-size: 14px;
    line-height: 24px;
  }

  b {
    font-weight: 500;
  }

  strong {
    font-weight: 600;
  }

  img {
    margin-bottom: auto;
  }
`

export const Stat = styled.p`
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
  font-family: Poppins;

  @media (max-width: 768px) {
    display: none;
  }
`

export const RecentStudy = styled.a`
  font-size: 12px;
  line-height: 16px;
  font-family: Poppins;
  text-decoration: underline;
  color: black;
`

export const Popover = styled.div`
  position: relative;
  display: inline-block;
  img {
    vertical-align: middle;
    cursor: pointer;
  }
  .popover-content {
    display: none;
  }

  &:hover .popover-content,
  &:focus .popover-content {
    position: absolute;
    transform: translate(-160px, 8px);
    display: block;
    border-radius: 4px;
    background: ${colors.c_grey[50]};
    width: 320px;
    box-shadow: 0px 1px 0px 0px #d1d3d7 inset, 0px -1px 0px 0px #d1d3d7 inset,
      1px 0px 0px 0px #d1d3d7 inset, -1px 0px 0px 0px #d1d3d7 inset;
    padding: 8px 16px;
    p {
      text-align: left;
      color: ${colors.c_primary[900]};
      font-family: Poppins;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
    @media (max-width: 834px) {
      width: 215px;
    }
  }
`

export const TriangleContainer = styled.div`
  position: absolute;
  transform: translate(0, 0);
  top: -5px;
  left: 162px;
  right: 0;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d1d3d7;

  .inner-triangle {
    position: relative;
    right: 5px;
    top: 1px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${colors.c_grey[50]};
  }
`
