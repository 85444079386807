import React, { useState, useEffect, useContext } from "react"
import { FloatingCTA, CloseButton, MobileCTA, StyledImage } from "./styled"
import { H5 } from "../../../ui/Heading/styled"
import { GetStartedButton } from "../../../shared"
import Close from "../../../../images/close.svg"
import { KetchContext } from "../../../../contexts/KetchContext"

export const CTAHomePage = () => {
  const [showCTA, setShowCTA] = useState(false)
  const { hasUserTakenActionOnKetchConsent } = useContext(KetchContext)

  useEffect(() => {
    const hasClosedCTA = localStorage.getItem("closedCTA")

    if (hasUserTakenActionOnKetchConsent && !hasClosedCTA) {
      setTimeout(() => {
        setShowCTA(true)
      }, 2000)
    }
  }, [hasUserTakenActionOnKetchConsent])

  const handleCloseCTA = () => {
    localStorage.setItem("closedCTA", "true")
    setShowCTA(false)
  }

  if (!showCTA) {
    return null // Don't render CTA if it's closed
  }

  return (
    <>
      {/* Desktop Floating CTA */}
      {
        <FloatingCTA>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <H5>Take the first step</H5>
            <CloseButton onClick={handleCloseCTA} type="button">
              <StyledImage src={Close} alt="Close Dialog" />
            </CloseButton>
          </div>
          <p>Choose a clinician you’ll love working with</p>
          <GetStartedButton height={40} />
        </FloatingCTA>
      }
      {
        <>
          <MobileCTA>
            <H5>Take the first step</H5>
            <div style={{ display: "flex", alignItems: "center" }}>
              <GetStartedButton height={32} />
              <CloseButton onClick={handleCloseCTA}>
                <StyledImage src={Close} alt="Close Dialog" />
              </CloseButton>
            </div>
          </MobileCTA>
        </>
      }
    </>
  )
}
