import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { FindAClinicianPageBody } from "./styled"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { SeoData, EndCapTitleData } from "./constants"
import {
  EndCap,
  FAQSection,
  StatsBlueBackground,
  SuccessStories,
} from "../shared"
import { OnlineAccessConvenience } from "./sections/OnlineAccessConvenience"
import { ClinicianSearchLoader } from "./sections/ClinicianSearchLoader/ClinicianSearchLoader"
import { SimpleScheduling } from "./sections/SimpleScheduling"
import { QualityCare } from "./sections/QualityCare"
import { useRolloutFlag } from "../../store/rolloutFlag"

export interface FindAClinicianPageProps {
  id: number
  name: string
  degrees: string[]
  languages: string[]
  specialties: string[]
  role: "prescriber" | "therapist"
  bio: string
}

export const FindAClinicianBody = ({
  role,
}: {
  role: "therapist" | "prescriber"
}): JSX.Element => {
  const faqLabel = role === "prescriber" ? "fap_faq" : "fat_faq"
  const endCapTitle = EndCapTitleData[role] ?? EndCapTitleData.therapist

  const { flag } = useRolloutFlag()

  return (
    <FindAClinicianPageBody>
      <ClinicianSearchLoader
        serviceName="ClinicianSearch"
        serviceHost="https://main.d36dd80ip6ib6q.amplifyapp.com"
        props={{ role, featureFlags: flag }}
      />
      <>
        <StatsBlueBackground />
        <SimpleScheduling />
        <QualityCare />
      </>
      <OnlineAccessConvenience role={role} />
      <SuccessStories />
      <EndCap title={endCapTitle} />
      <FAQSection labels={[faqLabel]} />
    </FindAClinicianPageBody>
  )
}

export const FindAClinicianPage = ({
  role,
}: {
  role: "therapist" | "prescriber"
}): JSX.Element => {
  return (
    <>
      <SEO {...(SeoData[role] ?? {})} schemaMarkup={organizationSchemaMarkup} />
      <Navigation />
      <FindAClinicianBody role={role} />
    </>
  )
}
