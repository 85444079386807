import styled from "styled-components"
import { colors } from "../../themes/colors"
import { Link } from "@reach/router"

export const SuccessStoriesWrapper = styled.section<{
  variant?: "secondary"
}>`
  background: ${({ variant }) =>
    variant === "secondary"
      ? "#F2F5FF"
      : "linear-gradient(135deg, #fef9ec 0%, #eceffe 100%)"};
  padding: 64px 24px;

  @media (max-width: 1050px) {
    padding: 48px 0px;
  }

  h2 {
    color: ${colors.c_black[900]};
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 23px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 28px;
    }
  }
`

export const DektopCardContainer = styled.div`
  width: 992px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;
  padding-top: 17px;

  @media (max-width: 1050px) {
    display: none;
  }
`

export const TestimonialsButton = styled(Link)<{
  variant?: "secondary"
}>`
  border-radius: 100px;
  background: ${({ variant }) =>
    variant === "secondary" ? "none" : colors.c_primary[600]};
  display: flex;
  height: 56px;
  max-width: 320px;
  margin: 40px auto 0;
  justify-content: center;
  align-items: center;
  color: ${({ variant }) =>
    variant === "secondary" ? "#51459e" : colors.c_white[100]};
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: ${({ variant }) =>
    variant === "secondary" ? "underline" : "none"};
  width: 100%;
  cursor: pointer;
  @media (max-width: 834px) {
    max-width: 600px;
  }
`

export const Card = styled.div`
  border-radius: 16px;
  background: ${colors.c_white[100]};
  padding: 24px;
  width: 320px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
`

export const QuoteText = styled.p`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-height: 168px;
  margin-top: 16px;
`

export const QuoteName = styled.p`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`

export const MobileCardContainer = styled.div`
  @media (max-width: 1050px) {
    display: block;
    padding: 0px 24px;
  }
  display: none;
`
export const QuoteTitle = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 16px 0;
`

export const DesktopCardWrapper = styled.div`
  margin: auto;
`
