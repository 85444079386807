import { useState } from "react"
import {
  SubMenuWrapper,
  SubMenuHeader,
  BackIcon,
  SubItemList,
  SubMenuItem,
} from "./styled"
import { NavigationItem, Options } from "../types"
import { RESOURCES } from "../constants"
import RightChevron from "../assets/fi_chevron-right.svg"
import LeftChevron from "../assets/chevron-left.svg"
import CloseIconImg from "../assets/close-icon.svg"
import { MobileButtonContainer } from "../shared/MobileButtonContainer"
import { CloseIcon, Divider } from "../shared/styled"
import { BlogPostPillBody } from "../../../../components/BlogPostPill/BlogPostPill"
import { NavDrawerTestimonial } from "../NavDrawerTestimonial/NavDrawerTestimonial"
import { Link } from "gatsby"
import { Community } from "../Community/Community"
import { MentalHealthTest } from "../MentalHealthTest"

interface SubMenuProps {
  closeMobileMenu: () => void
  closeSubMenu: () => void
  subMenuData: NavigationItem | null
  isBannerShown?: boolean
  isOpen: boolean
  disableTransition: boolean
}

export const SubMenu = ({
  closeMobileMenu,
  closeSubMenu,
  subMenuData,
  isBannerShown,
  isOpen,
  disableTransition,
}: SubMenuProps) => {
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState<string | null>(
    null
  )

  const handleSubMenuItemClick = (itemTitle: string) => {
    if (selectedSubMenuItem === itemTitle) {
      closeMobileMenu()
      return
    }

    if (!selectedSubMenuItem) {
      setSelectedSubMenuItem(itemTitle)
      closeMobileMenu()
      return
    }
  }

  return (
    <SubMenuWrapper
      isBannerShown={isBannerShown}
      isOpen={isOpen}
      disableTransition={disableTransition}
    >
      <SubMenuHeader>
        <BackIcon onClick={closeSubMenu}>
          <img src={LeftChevron} alt="left chevron arrow" />
          <p>{subMenuData?.mainMenuTitle}</p>
        </BackIcon>
        <CloseIcon
          src={CloseIconImg}
          alt="close icon"
          onClick={() => closeMobileMenu()}
        />
      </SubMenuHeader>
      <Divider />
      <SubItemList>
        {subMenuData?.subMenuOptions.map(item => (
          <>
            {item.link ? (
              <Link
                to={item.link}
                onClick={() => handleSubMenuItemClick(item.subMenuTitle)}
                tabIndex={-1}
              >
                <SubMenuItem key={item.subMenuTitle}>
                  <button>{item.subMenuTitle}</button>
                  {item.link && (
                    <img src={RightChevron} alt="right chevron arrow" />
                  )}
                </SubMenuItem>
              </Link>
            ) : (
              <SubMenuItem key={item.subMenuTitle} noLink>
                <button disabled={true}>{item.subMenuTitle}</button>
              </SubMenuItem>
            )}

            {item.additionalComponent?.type === "Testimonial" && (
              <NavDrawerTestimonial
                servicePlan={item.additionalComponent.testimonialType}
              />
            )}
            {item.additionalComponent?.type === "Pill" && <BlogPostPillBody />}
          </>
        ))}
      </SubItemList>

      {/* MentalHealthTest, Community section should only live on the resources submenu */}
      {subMenuData?.mainMenuTitle === RESOURCES && (
        <>
          <MentalHealthTest />
          <Community />
        </>
      )}
      {isOpen && <MobileButtonContainer />}
    </SubMenuWrapper>
  )
}
