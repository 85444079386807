import { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import styled from "styled-components"
import { graphql } from "gatsby"
import Pagination from "../components/Pagination"
import BlogRepoHeader from "../components/shared/BlogRepo/BlogRepoHeader"
import { edgeToPostPreview } from "../lib/utils"
import { Post } from "../components/shared/Blog/types"
import BlogListing from "../components/shared/BlogRepo/BlogListing"
import { BlogCategories } from "../components/shared/BlogRepo/BlogCategories"
import { Navigation } from "../components/ui/Navigation"
import { StatsBlueBackground } from "../components/shared"
import EndBlog from "../components/shared/Blog/EndBlog"
import { EmailCollectionPopup } from "../components/shared/EmailCollectionPopup"
import { useEmailCollectionPopup } from "../components/shared/BlogRepo/useEmailCollectionPopup"

const postsPerPage = 9

export const BlogListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;

  @media (max-width: 720px) {
    margin-top: 48px;
  }
`

export const BlogPostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: auto;
  align-items: center;
  overflow-anchor: none;
`

interface BlogPageProps {
  data: {
    all: {
      edges: Post[]
    }
  }
}

const BlogPage = ({ data }: BlogPageProps) => {
  const posts = data.all.edges.map(edge => edgeToPostPreview({ edge }))
  const categories = [...new Set(posts.map(post => post.categories).flat())]
    .filter(category => Boolean(category))
    .sort()

  const lastPost = Math.min(postsPerPage, posts.length)
  const pagePosts = [...posts].slice(0, lastPost)

  const [activeCategories, setActiveCategories] = useState<string[]>()
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([])
  const [currentPagePosts, setCurrentPagePosts] = useState<Post[]>(pagePosts)
  const [currentPage, setCurrentPage] = useState(0)
  const { showModal, setShowModal } = useEmailCollectionPopup()

  const getInitialCategories = () => {
    const currentURL = document.location.search
    const searchParams = new URLSearchParams(currentURL)
    const initialCategories =
      [...new Set(searchParams.get("categories")?.split("|"))] || null

    if (initialCategories[0] !== "") {
      const currentURL = document.location.search
      const searchParams = new URLSearchParams(currentURL)
      searchParams.set("categories", initialCategories.join("|"))
      window.history.pushState({}, "", `blog?${searchParams}`)
      setActiveCategories(initialCategories)
      return
    }
    setActiveCategories([])
  }

  const updateActiveCategories = (category: string) => {
    const index = activeCategories ? activeCategories.indexOf(category) : -1
    const newCategories = [...(activeCategories || [])]

    const currentURL = document.location.search
    const searchParams = new URLSearchParams(currentURL)

    if (index >= 0) {
      newCategories.splice(index, 1)
      setActiveCategories(newCategories)
      searchParams.set("categories", newCategories.join("|"))
      window.history.pushState({}, "", `blog?${searchParams}`)
      return
    }

    searchParams.set("categories", newCategories.join("|"))
    window.history.pushState({}, "", `blog?${searchParams}`)
    newCategories.push(category)
    setActiveCategories(newCategories)
    setCurrentPage(0)
  }

  const updatePagePost = () => {
    const postList = filteredPosts.length > 0 ? filteredPosts : posts
    const lastPost = Math.min(
      currentPage * postsPerPage + postsPerPage,
      postList.length
    )
    const pagePosts = [...postList].slice(currentPage * postsPerPage, lastPost)
    setCurrentPagePosts(pagePosts)
  }

  useEffect(() => {
    if (activeCategories != undefined) {
      if (activeCategories.length > 0 && activeCategories !== categories) {
        const currentURL = document.location.search
        const searchParams = new URLSearchParams(currentURL)
        searchParams.set("categories", activeCategories.join("|"))
        window.history.pushState({}, "", `blog?${searchParams}`)
      }

      if (
        activeCategories.length == categories.length ||
        activeCategories.length == 0
      ) {
        const currentURL = document.location.search
        const searchParams = new URLSearchParams(currentURL)
        searchParams.delete("categories")
        window.history.pushState({}, "", `blog`)
      }

      const filtered = posts.filter(post =>
        post.categories
          .flat()
          .some((category: any) => activeCategories?.includes(category))
      )

      setFilteredPosts(activeCategories.length > 0 ? filtered : posts)
    }
  }, [activeCategories])

  useEffect(() => {
    updatePagePost()
  }, [currentPage, filteredPosts])

  useEffect(() => {
    getInitialCategories()
  }, [])

  return (
    <Layout useKameleoon={true}>
      <SEO
        title="Cerebral Blog"
        titleSuffix="Learn how to diagnose and treat anxiety, depression and insomnia from our team of experts"
        description="Check out the Cerebral blog. Learn how to diagnose and treat your anxiety, depression and insomnia, and whether medication is right for you"
      />
      <Navigation isBannerShown={false} />
      {showModal && (
        <EmailCollectionPopup onClose={() => setShowModal(false)} />
      )}
      <BlogRepoHeader posts={posts} />
      <StatsBlueBackground />
      <BlogListingWrapper>
        <BlogCategories
          categories={categories}
          activeCategories={activeCategories || []}
          onSelected={category => updateActiveCategories(category)}
        />
        <BlogPostListContainer>
          <BlogListing posts={currentPagePosts} />
        </BlogPostListContainer>
        <Pagination
          itemsCount={
            filteredPosts.length > 0 ? filteredPosts.length : posts.length
          }
          itemsPerPage={postsPerPage}
          onPageChange={page => {
            setCurrentPage(page)
          }}
        />
      </BlogListingWrapper>
      <EndBlog />
    </Layout>
  )
}

export const query = graphql`
  query BlogPageQuery($lang: String) {
    all: allPrismicPost(
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            content {
              text
            }
            previewimage {
              gatsbyImageData(
                width: 834
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
            author_name {
              text
            }
            date(formatString: "MM/DD/YYYY")
            categories {
              category
            }
            body {
              ... on PrismicPostDataBodyBlogText {
                primary {
                  text {
                    text
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`

export default BlogPage
