import Cookies from "js-cookie"
import { useEffect } from "react"
import { useRef } from "react"
import segment from "../../lib/segment"
import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { HomePageWrapper, BackgroundGradient } from "./styled"
import { FAQSection, BigHero } from "../shared"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { getVariantByName } from "../../lib/utils"
import {
  CheckYourInsurance,
  HeroTitle,
  WorkWithAClinician,
  WhatOurClientsAreSaying,
  HowCanWeHelp,
  HowItWorks,
  AppImages,
  CTAHomePage,
} from "./sections"

import Hero from "./assets/hero.webp"

export const HomePageBody = (): JSX.Element => {
  const workWithAClinicianRef = useRef<HTMLDivElement | null>(null)

  const learnMoreClicked = () => {
    if (workWithAClinicianRef.current) {
      const top = workWithAClinicianRef.current.getBoundingClientRect().top
      window.scrollTo({ top: top + window.scrollY - 120, behavior: "smooth" })
    }
  }

  return (
    <HomePageWrapper>
      <BackgroundGradient>
        <BigHero
          Title={HeroTitle}
          image={Hero}
          learnMoreClicked={learnMoreClicked}
        />
        <WorkWithAClinician ref={workWithAClinicianRef} />
      </BackgroundGradient>
      <CheckYourInsurance />
      <HowItWorks />
      <HowCanWeHelp />
      <WhatOurClientsAreSaying />
      <AppImages />
      <FAQSection labels={["homepage_faq"]} />
      {getVariantByName("Chatbot Widget") !== "Active" && <CTAHomePage />}
    </HomePageWrapper>
  )
}

export const HomePage = (): JSX.Element => {
  useEffect(() => {
    // To track Impact conversions
    segment.setImpactClickID()

    // Sets cookie for testing segment on DEV enviroments
    const params = new URLSearchParams(window.location.search)
    if (
      params.get("enable_segment_track") &&
      !Cookies.get("enable_segment_track")
    ) {
      Cookies.set("enable_segment_track", "true", { expires: 1 })
    }
  }, [])

  const seoTitle =
    "Online Mental Health Care That Caters to You | Cerebral | Online Therapy | Virtual Counseling | Telehealth Therapy"
  const seoDescription =
    "Cerebral experts offer online mental healthcare and online therapy for anxiety, depression, insomnia, and more. Learn about the care you can get today."

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        url="/"
        schemaMarkup={organizationSchemaMarkup}
        titleOverride
      />
      <Navigation />
      <HomePageBody />
    </>
  )
}
