import React, { useState, useRef, useEffect } from "react"
import { IInsurancePayer } from "../types"
import {
  DropdownItem,
  DropdownMenu,
  DropdownWrapper,
  ErrorTooltip,
} from "./styled"
import CircleX from "../../../assets/circle-x.svg"
import ChevronUp from "../../../assets/chevron-up.svg"
import ChevronDown from "../../../assets/chevron-down.svg"
import { colors } from "../../../../themes/colors"
import Triangle from "../../../assets/triangle.svg"

interface IPayerDropdownProps {
  options: IInsurancePayer[]
  onSelect: (option: IInsurancePayer | null) => void
  LeftIcon: ({ fill }: { fill: string }) => JSX.Element
  value: IInsurancePayer | null
  error?: string
  isDisabled?: boolean
  placeholder?: string
}

export const CarrierDropdown = ({
  options,
  onSelect,
  LeftIcon,
  value,
  error,
  isDisabled = false,
  placeholder,
}: IPayerDropdownProps) => {
  const [text, setText] = useState<string>(
    value ? value.display_name || value.label : ""
  )
  const [isOpen, setIsOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [isFocused, setIsFocused] = useState(false)
  const [filteredOptions, setFilteredOptions] =
    useState<IInsurancePayer[]>(options)
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const optionsRefs = useRef<Array<HTMLDivElement | null>>([])

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    setText(value ? value.display_name || value.label : "")
  }, [value])

  const toggleDropdown = () => {
    if (isDisabled) return
    inputRef.current?.focus()
    setIsFocused(true)
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsFocused(false)
    setText(value ? value.display_name || value.label : "")
    setIsOpen(false)
  }

  const handleInputChange = (value: string) => {
    setText(value)
    setFilteredOptions(
      options.filter(option =>
        (option.display_name || option.label)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    )
    if (!isOpen) setIsOpen(true)
  }

  const handleSelectOption = (option: IInsurancePayer) => {
    onSelect(option)
    setIsOpen(false)
    setText(option.display_name || option.label)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (filteredOptions.length < 1) return

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        setCurrentIndex(prevIndex => (prevIndex + 1) % filteredOptions.length)
        break
      case "ArrowUp":
        event.preventDefault()
        setCurrentIndex(
          prevIndex =>
            (prevIndex - 1 + filteredOptions.length) % filteredOptions.length
        )
        break
      case "Enter":
        event.preventDefault()
        if (currentIndex >= 0 && currentIndex < filteredOptions.length) {
          handleSelectOption(filteredOptions[currentIndex])
        }
        break
      default:
        break
    }
  }

  const handleXClick = () => {
    onSelect(null)
    setText("")
    handleInputChange("")
  }

  return (
    <DropdownWrapper
      ref={dropdownRef}
      onClick={toggleDropdown}
      onBlur={closeDropdown}
      onKeyDown={handleKeyDown}
      isFocused={isFocused}
      error={!!error}
      data-testid="carrier-dropdown"
    >
      <LeftIcon
        fill={isDisabled ? colors.c_grey[500] : colors.c_primary[600]}
      />
      <input
        ref={inputRef}
        value={text}
        placeholder={placeholder ?? "Select an insurance payer"}
        onChange={e => handleInputChange(e.currentTarget.value)}
        type="text"
        disabled={isDisabled}
      />
      {value ? (
        <img src={CircleX} alt="" onClick={handleXClick} />
      ) : (
        <img src={isOpen ? ChevronUp : ChevronDown} alt="" />
      )}
      {isOpen && (
        <DropdownMenu>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <DropdownItem
                key={option.value}
                ref={el => (optionsRefs.current[index] = el)}
                selected={index === currentIndex}
                onMouseDown={() => handleSelectOption(option)}
                onMouseOver={() => setCurrentIndex(index)}
              >
                {option.display_name || option.label}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem selected={false} onMouseDown={handleXClick}>
              Sorry, no results
            </DropdownItem>
          )}
        </DropdownMenu>
      )}
      {error && !isOpen && (
        <ErrorTooltip>
          <img src={Triangle} alt="" />
          <span>{error}</span>
        </ErrorTooltip>
      )}
    </DropdownWrapper>
  )
}
