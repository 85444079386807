import { StatsProps } from "../prismic/Stats"
import { HeaderWithInsuranceLogosProps } from "../prismic/HeaderWithInsuranceLogos"
import { IGatsbyImageData } from "gatsby-plugin-image"
import InsuranceBanner from "./insurance-banner.webp"
import Cigna from "./logos/cigna.png"
import BlueCross from "./logos/blue-cross.png"
import Humana from "./logos/humana.png"
import UnitedHealth from "./logos/united-healthcare.png"
import Optum from "./logos/optum.png"
import CareTeam from "./sections/YourPersonalPath/assets/care-team.svg"
import Form from "./sections/YourPersonalPath/assets/form.svg"
import HandsHeart from "./sections/YourPersonalPath/assets/hands-heart.svg"
import { Plan } from "./sections/YourPersonalPath/constants"
import appSc2 from "./sections/AppFeatures/assets/app_screenshot_2.webp"
import appSc3 from "./sections/AppFeatures/assets/app_screenshot_3.webp"
import appSc4 from "./sections/AppFeatures/assets/app_screenshot_4.webp"
import appSc6 from "./sections/AppFeatures/assets/app_screenshot_6.webp"
import appSc5 from "./sections/AppFeatures/assets/app_screenshot_5.webp"

const logoImage = (
  src: string,
  alt: string
): { insurance_logo: { gatsbyImageData: IGatsbyImageData; alt: string } } => ({
  insurance_logo: {
    alt,
    gatsbyImageData: {
      images: {
        fallback: {
          src,
        },
      },
    } as unknown as IGatsbyImageData,
  },
})

export const insuranceSlice: HeaderWithInsuranceLogosProps["slice"] = {
  primary: {
    heading: {
      text: "Mental healthcare covered by insurance",
    },
    copy: {
      text: "Work with a therapist or prescriber in a safe, judgement-free space.",
    },
    cta_text: { text: "Check my coverage" },
    cta_link: { url: "https://forms.cerebral.com/to/EtjRbfRz" },
    waitlist_link: { url: "https://forms.cerebral.com/to/KV3bSpek" },
    banner_image: {
      alt: "Insurance banner",
      gatsbyImageData: {
        images: {
          fallback: {
            src: InsuranceBanner,
            sizes: "1216px",
          },
        },
        layout: "fixed",
        width: 1216,
        height: 516,
      },
    },
  },
  items: [
    logoImage(Cigna, "Cigna"),
    logoImage(BlueCross, "Blue Cross Blue Shield"),
    logoImage(Humana, "Humana"),
    logoImage(UnitedHealth, "United Healthcare"),
    logoImage(Optum, "Optum"),
  ],
}

export const statsSlice: StatsProps["slice"] = {
  primary: {
    platform_title: {
      html: "Best Telehealth <br /> Platform 2023",
    },
    platform_description: {
      html: "MedTech <br /> Breakthrough",
    },
    clients_helped_title: {
      html: "500k+",
    },
    clients_helped_description: {
      html: "clients <br /> helped",
    },
    rating_title: {
      html: "4.6",
    },
    rating_description: {
      html: "rating in the <br /> App Store",
    },
    forbes_description: {
      html: "Forbes",
    },
    background_color: "#ffffff",
  },
}

export const personalPathPlans: Plan[] = [
  {
    icon: CareTeam,
    title: "Individual Therapy",
    description:
      "Meet 1-on-1 with a credentialed compassionate therapist via video chat or over the phone",
    link: "/plans/therapy",
  },
  {
    icon: Form,
    title: "Medication",
    description:
      "Comprehensive medication and lifestyle guidance from a licensed nurse practitioner",
    link: "/plans/medication",
  },
  {
    icon: HandsHeart,
    title: "Therapy + Medication",
    description:
      "Build long-term coping skills in therapy and reduce symptoms with medication at the same time",
    link: "/plans/therapy",
  },
]

export const cerebralWayCopy = [
  {
    header: "Initial evaluation",
    description:
      "Meet with your therapist or prescriber to talk through where you’re at and why you’re here. Your first session includes a clinical evaluation to help determine a diagnosis. ",
  },
  {
    header: "Personalized treatment plans",
    description:
      "Based on your first session, you and your clinician will build a personalized treatment plan to encourage the best possible outcomes in your mental health.",
  },
  {
    header: "Keep track of progress",
    description:
      "Monthly assessments help keep a record of your journey. After 90 days, a dedicated check-in session with your clinician lets you take a pause to discuss progress, set new goals, or adjust your treatment plan.",
  },
]

export const appFeatures = [
  {
    text: "Book a session easily online",
    img: appSc2,
  },
  {
    text: "See upcoming appointments + reminders",
    img: appSc3,
  },
  {
    text: "Get detailed summaries of each session",
    img: appSc4,
  },
  {
    text: "Unlimited messaging with your care team",
    img: appSc6,
  },
  {
    text: "Track med refills and shipping status",
    img: appSc5,
  },
]

export const successStories = [
  {
    quote:
      "I’ve spent months trying to find accessible therapy and prescribers for my medication. My provider was able to help me with my medication within our 30 min call. She was great and I just feel super grateful I found this app.",
    name: "Mirella",
  },
  {
    quote:
      "My overall experience with Cerebral has been exceptional - their customer service is great, responsive and always helpful..and my therapist has been life-changing! ",
    name: "Leanna",
  },
  {
    quote:
      "My medical team is professional, responsive, easy to reach, and I feel confident that my mental health issues will be managed properly. I have hope and don’t feel like like “just another case.” They see me as a person.",
    name: "Jacqueline",
  },
]
